<template>
    <div class="descktop-trip__arrivals" v-if="translates && translates[langUrl]">
        <template v-for="(arrival, i) in arrivals">
            <div :key="'arrival' + i" class="descktop-trip__arrivals__arrival">
                <div class="descktop-trip__arrivals__arrival__info">
                    <div class="descktop-trip__arrivals__arrival__dates">
                        <div class="descktop-trip__arrivals__arrival__dates__start">{{ arrival.start | moment('DD MMM YYYY') }}</div>
                        <img src="../../../assets/ArrowCircleRight.svg" />
                        <div class="descktop-trip__arrivals__arrival__dates__finish">{{ arrival.finish | moment('DD MMM YYYY') }}</div>
                    </div>
                    <div class="descktop-trip__arrivals__arrival__price">
                        <div class="descktop-trip__arrivals__arrival__price__final-price"><span v-html="priceFormated(priceWithSale(arrival), arrival.currency)"></span></div>
                        <div 
                            v-if="arrival.discount && arrival.discount > 0" 
                            class="descktop-trip__arrivals__arrival__price__percent"> {{ arrival.discount }}%
                        </div>
                        <div 
                            v-if="arrival.discount && arrival.discount > 0" 
                            class="descktop-trip__arrivals__arrival__price__default-price"><span v-html="priceFormated(arrival.price, arrival.currency)"></span>
                        </div>
                    </div>
                </div>
                
                <div class="descktop-trip__arrivals__arrival__description">{{ translates[langUrl].offer[lang] }}</div>
                <BaseButton
                    v-if="checkArrival(arrival)"
                    class="button"
                    style="width: 184px; height: 63px;"
                    @click="payBooking(arrival)">{{ translates[langUrl].button_booking[lang] }}</BaseButton>
                <BaseButton
                    v-else
                    class="button-fill"
                    style="width: 184px; height: 63px;"
                    @click="createBooking(arrival)">{{ translates[langUrl].button_reservation[lang] }}</BaseButton>
            </div>
        </template>
    </div>
</template>

<script>
    import moment from '@/plugins/moment';
    import store from '@/store';
    import { mapState } from 'vuex';
    import { priceFormated } from '@/helpers';
    import { BOOKING_STATUS } from '@/vars';
    import BaseButton from '../BaseButton';

    export default {
        name: 'Arrivals',
        components: {
            BaseButton
        },
        props: {
            tripId: { 
                type: String,
                default: () => null
            },
            arrivals: { 
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            feedback: {
                trip: '',
                name: '',
                phone: '',
                email: '',
                comment: '',
                link: ''
            },
            BOOKING_STATUS,
            priceFormated,
            langUrl: 'arrivals'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('bookings', {
                bookings: state => {
                    return state.entities.filter(item => { return item.status !== BOOKING_STATUS.CANCELED })
                }
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            if (this.user._id) {
                await store.dispatch('bookings/fetch', {});
            }
        },
        methods: {
            priceWithSale(arrival) {
                if (arrival.discount){
                    return Math.round(arrival.price * ((100 - arrival.discount) / 100) )
                } else {
                    return arrival.price
                }
                
            },
            checkArrival(arrival) {
                for(const booking of this.bookings) {
                    if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                        return true;
                    }
                }
                return false;
            },
            async createBooking(arrival) {
                if(this.user._id) {
                    for(const booking of this.bookings) {
                        if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                            await store.dispatch('bookings/delete', { id: booking._id });
                        }
                    }
                    store.commit('bookings/SET_ENTITY', {
                        trip: this.tripId,
                        arrival: arrival._id,
                        start: arrival.start,
                        finish: arrival.finish,
                        price: arrival.price,
                        discount: arrival.discount,
                        currency: arrival.currency,
                    });
                    await store.dispatch('bookings/fetch', {});
                    await store.dispatch('trips/get', { id: this.tripId });
                    const booking = await store.dispatch('bookings/save');
                    this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        for(const booking of this.bookings) {
                            if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                                await store.dispatch('bookings/delete', { id: booking._id });
                            }
                        }
                        store.commit('bookings/SET_ENTITY', {
                            trip: this.tripId,
                            arrival: arrival._id,
                            start: arrival.start,
                            finish: arrival.finish,
                            price: arrival.price,
                            discount: arrival.discount,
                            currency: arrival.currency,
                        });
                        await store.dispatch('bookings/fetch', {});
                        await store.dispatch('trips/get', { id: this.tripId });
                        const booking = await store.dispatch('bookings/save');
                        this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                    });
                }
            },
            async deleteBooking(arrival) {
                if(this.user._id) {
                    for(const booking of this.bookings) {
                        if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                            await store.dispatch('bookings/delete', { id: booking._id });
                        }
                    }
                    await store.dispatch('bookings/fetch', {});
                    await store.dispatch('trips/get', { id: this.tripId });
                } else {
                    this.authorization()
                }
            },
            async payBooking(arrival) {
                if(this.user._id) {
                    for(const booking of this.bookings) {
                        if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                            this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                        }
                    }
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        for(const booking of this.bookings) {
                            if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                                this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                            }
                        }
                    });
                }
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
        }
    }
</script>

<style lang="scss">
    .descktop-trip__arrivals {
        display: flex;
        flex-direction: column;
        max-width: 840px;
        &__arrival{
            display: flex;
            flex-direction: row;
            padding: 28px 40px;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-top: 20px;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
            &__dates {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                padding-bottom: 10px;
                // border-bottom: 1px solid #F6F7F9;
                &__day {
                    font-size: 14px;
                    line-height: 140%;
                    color: #9FA5AD;
                }
            }
            &__price {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.02em;
                color: #3A4256;
                &__percent {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 45px;
                    height: 22px;
                    background: #3A4256;
                    border-radius: 20px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    color: #FFFFFF;  
                }
                &__final-price{
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: -0.02em;
                    color: #3A4256;
                }
                &__default-price {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: -0.02em;
                    text-decoration-line: line-through;
                    color: #9FA5AD;
                    flex: none;
                }
            }
            &__info {
                width: 300px; 
                display: flex; 
                flex-direction: column; 
                justify-content: center; 
                padding-right: 35px;
            }
            &__description {
                width: 235px;
                display: flex; 
                align-items: center; 
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                padding: 0 35px;
                margin-right: 40px;
                border-right: 1px solid #EBEBEB;
                border-left: 1px solid #EBEBEB;
            }
        }
    }
</style>